const PanelContainer = {
  baseStyle: {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    p: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
