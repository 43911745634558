// React imports
import React,{ useState , useEffect } from 'react';


// Homekit imports
import { LightCard } from "../../../homekitpack";

// MQTT

import { useMqttState , useSubscription } from 'mqtt-react-hooks'

// OpenHasp
// GoToolBox
// Tasmota


export function HKLightCard(props) {
  const topic = props.topic;
  const name = props.name;
  const dimmer = props.dimmer;
  const rgb = props.rgb;
  const publish = props.publish;
  const platform = props.platform;
  const [ on, setOn ] = React.useState(props.on ? props.on : false);
  const [ brightness, setBrightness ] = React.useState(255);
  const brightnessPercentage = Math.floor(brightness * 100/255);



  // MQTT
  const { client } = useMqttState();
  const { message } = useSubscription([
    topic ? topic : "react/noTopic",
    //'stat/raceland_BF7310/POWER',
    // 'cmnd/raceland_BF7310/POWER',
  ]);

  // useEffect(() => {
  //   if (message) {
      
  //   }
  // }, [message]);


  
  useEffect(() => {
    if (message) {
      // console.log("LIGHTHK", message);
      if (message.message === 'ON'){
        setOn(true);
      } else {
        setOn(false);
      }
    }
  }, [message]);




  function handleToggle() {
    return client.publish('cmnd/raceland_BF7310/POWER', "2");
  }

  function handlePercentageChange(value) {
    const brightness = Math.floor(value*255/100);
    props.hass.callService('light', 'turn_on', {
      entity_id: props.entityId,
      brightness: brightness,
    });
  }

  return (
    <LightCard
      name={name}
      on={on}
      brightness={dimmer ? brightnessPercentage : ''}
      onBrightnessChange={handlePercentageChange}
      onToggle={handleToggle}
      capabilities={{
        SUPPORT_BRIGHTNESS: dimmer
      }}
    />
  );
}
