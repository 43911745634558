import {Flex} from "@chakra-ui/react";
import LogoRaceland from "assets/img/favicon-logoauto.png";
import React from "react";

export function SidebarHelp(props) {
  // Pass the computed styles into the `__css` prop
  const { children, ...rest } = props;
  return (
    <Flex
      borderRadius="15px"
      flexDirection="column"
      bgImage={LogoRaceland}
      justifyContent="flex-start"
      alignItems="start"
      boxSize="border-box"
      p="16px"
      h="50px"
      w="90%"
    >
    </Flex>
  );
}
