// Chakra imports
import {
  Flex,
  useColorMode,
  useColorModeValue,
  Box
} from "@chakra-ui/react";

// React imports
import React,{ useState , useEffect } from 'react';



// Homekit imports

import { ThemeProvider as HKThemeProvider } from '@emotion/react';
import { theme } from '../../style/theme';
import '../../font.css';
import { LightCard, SceneCard, ThermostatCard , CameraCard, SectionLayout} from "../../homekitpack";
import { HKLightCard } from "../../widgets/HKCards";

// jsonx
import * as jsonx from "jsonx";

const car = {type:"Fiat", model:"500", color:"white"};

// components
const getReactElementFromJSONX = jsonx.getReactElementFromJSONX.bind({ 
  reactComponents:{
    ChakraBox: Box,
    HKSectionLayout: SectionLayout,
    HKLight: HKLightCard,
  },
    // componentLibraries:{
    //   // HKemotion,
    //   // theme
    // }
});

// Default Configuration
let JXM = {
  component: "ChakraBox",
  props: {
    style: {
    padding: '10px',
    }},
  children: [
    {
      component: "HKSectionLayout",
      props: {
        title: "Cozinha2",
      },
      children: [
        {
          component: "HKLight",
          props:{
            name: "Regular Light",
            dimmer: false,
            on: false,
            topic: 'stat/raceland_BF7310/POWER'
          },
          children: {},
        }
      ],
    }
  ]
};


// MQTT
import { useMqttState , useSubscription } from 'mqtt-react-hooks'



function JSONString(value) {
  if(value) {
    console.log(value.message)
    try {
        var jsonret = JSON.parse(value.message.replace(new RegExp( "\\n", "g" ),""));
        return jsonret;
    } catch (e) {
        console.log("error", e);
        return false;
    }
  }
}

function JSXValidator(value){
  try{
    getReactElementFromJSONX(value)
    return true;
  } catch (e) {
    console.log("error", e);
    return false;
  }
  

}


// DASHBOARD


export default function Dashboard() {
  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const iconTeal = useColorModeValue("gray.300", "gray.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");

  const overlayRef = React.useRef();

  const [ reactJXM, setJXM ] = React.useState(JXM);

  // MQTT
  const { client } = useMqttState();
  const { message } = useSubscription([
    'testtopic/react',
  ]);

  useEffect(() => {
    console.log("STARTED");
    // return client.publish('cmnd/raceland_BF7310/POWER', "2");
  }, []);
  
  useEffect(() => {
    if (message) {
      setJXM(JSONString(message));
    }
  }, [message]);




  return (

  <>
    <Flex justifyContent="space-between" alignItems="center" flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <HKThemeProvider theme={theme}>

          <section>
            { JSXValidator(reactJXM) ? getReactElementFromJSONX(reactJXM) :"error" }
          </section>

          {/* <HKLightCard name="testing toggle" dimmer={false} topic='stat/raceland_BF7310/POWER' />
          <HKLightCard name="test dimmer" dimmer={true} topic='stat/raceland_BF7310/POWER' />
          <HKLightCard name="testing rgb" rgb={true} dimmer={true} topic='stat/raceland_BF7310/POWER' /> */}

        </HKThemeProvider>
    </Flex>
  </>

  );
}
