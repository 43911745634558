// Chakra imports
import {
  Flex,
  useColorMode,
  useColorModeValue,
  Box
} from "@chakra-ui/react";
// assets



// Custom components



// Custom icons


import React, { useState } from "react";


// Homekit imports
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../style/theme';
import '../../font.css';
import { LightCard, SceneCard, ThermostatCard , CameraCard, SectionLayout} from "../../homekitpack";
// import { LightCard, SceneCard, ThermostatCard , CameraCard} from "components";
import { ReactComponent as LogoIconSvg } from '../../assets/svg/cube.svg'


//color picker imports
import { Slider, Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome } from '@uiw/react-color';
import { Alpha, Hue, ShadeSlider, Saturation, Interactive, hsvaToHslaString } from '@uiw/react-color';
import { EditableInput, EditableInputRGBA, EditableInputHSLA } from '@uiw/react-color';


export default function Dashboard() {
  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const iconTeal = useColorModeValue("gray.300", "gray.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");

  const overlayRef = React.useRef();


  // Homekit vars
  const [ on, setOn ] = React.useState(true);
  const [isActive, setIsActive] = React.useState(true);


  // Color Picker vars
  const [hex, setHex] = useState("#fff");
  const [hsva, setHsva] = useState({ h: 0, s: 0, v: 68, a: 1 });

  return (
    <Flex justifyContent="space-between" alignItems="center" flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <div style={{
        padding: '10px',
        }}>
        <ThemeProvider theme={theme}>
        {/* <Box>
        <SceneCard
            isActive={isActive}
            name="Test scene"
            icon={
              <LogoIconSvg />
            }
            onPress={() => setIsActive(!isActive)}
          />        
          </Box> */}
          <Box> 
          <SceneCard
            isActive={isActive}
            name="Test scene"
            icon={
              <LogoIconSvg />
            }
            onPress={() => setIsActive(!isActive)}
          />
          </Box> 
          <Box>
          <CameraCard
            name="Backyard"
            previewImageSrc={"../../assets/img/camera_example.jpeg"}
          />
          </Box>
          <Box>
            <Wheel
              style={{ marginLeft: '25%', marginTop: '10%' }}
              color={hex}
              onChange={(color) => {
                setHex(color.hex);
              }}
            />
          </Box>
          <Box>
          <Circle
            style={{ marginLeft: '32%', marginTop: '10%' }}
            colors={[ '#F44E3B', '#00FE00', '#FCDC00', '#0092FE' ]}
            color={hex}
            onChange={(color) => {
              setHex(color.hex);
            }}
          />
          </Box>
          
        </ThemeProvider>
      </div>
    </Flex>
  );
}
