const CardHeader = {
  baseStyle: {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    UserSelect: "none",
    display: "flex",
    width: "100%"
  },
};

export const CardHeaderComponent = {
  components: {
    CardHeader,
  },
};
